import React from "react"
import { Router } from "@reach/router"

import { Dashboard } from "./Dashboard"
import { AntigenTest } from "./AntigenTest"
import { PCRTest } from "./PCRTest"
import { ScrollToTop } from ".."

export const InputResults = () => (
  <Router>
    <ScrollToTop path="/">
      <Dashboard path="/" />
      <AntigenTest path="antigen-test" />
      <PCRTest path="pcr-test" />
    </ScrollToTop>
  </Router>
)
