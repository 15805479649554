import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

import { Button, FormLegend, FormFooter } from "../../../shared"
import { handleAPIError } from "../../../../utilities"
import { useSelfTest } from ".."
import { ModalImageContainer } from "../ModalImageContainer"

export const Step4 = ({ images, inteliSwabImages }) => {
  const { testData, setTestData, addToast } = useSelfTest()
  const [loading, setLoading] = React.useState(false)

  const logStartTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: {
            startedTime: new Date().toISOString(),
          },
          _expand: ["patient"],
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        data: { ...updatedTest },
      }))
      // setLoading not used to preent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "start timer"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }

  return (
    <>
      {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
        <>
          <FormLegend>
            <p>
              Please ask your patient to insert the InteliSwab into the
              extraction tube. Swirl 10 times, and leave the swab in the tube
              when they're done.
            </p>
          </FormLegend>
          <FormLegend>
            <p>Patient: {testData?.data?.patient?.fullName}</p>
          </FormLegend>
          <ModalImageContainer svg={true} images={inteliSwabImages} />
        </>
      ) : (
        <>
          <FormLegend>
            <p>
              Please ask your patient to prepare their test result according to
              manufacturer instructions.
            </p>
            <p>When they're ready to wait for the result, start the timer</p>
          </FormLegend>
          <FormLegend>
            <p>Patient: {testData?.data?.patient?.fullName}</p>
          </FormLegend>
          <ModalImageContainer images={images} />
        </>
      )}
      <FormFooter>
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logStartTime()}
        >
          Log result start time
        </Button>
      </FormFooter>
    </>
  )
}

Step4.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  inteliSwabImages: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
