import React from "react"
import { useLocation } from "@reach/router"

import { Breadcrumb } from "./Breadcrumb"

export const Breadcrumbs = () => {
  const location = useLocation()

  return (
    <ul className="breadcrumbs">
      <Breadcrumb name="Dashboard" link="/" chevron={false} />
      {location?.pathname === "/antigen-test" ? (
        <Breadcrumb name="Antigen Test Result" link="/antigen-test" />
      ) : location?.pathname === "/pcr-test" ? (
        <Breadcrumb name="PCR Test Result" link="/pcr-test" />
      ) : null}
    </ul>
  )
}
