import React from "react"

import { useAuth } from "../../hooks"
import { Button } from "../shared"

export const AccountNotApproved = () => {
  const auth = useAuth()

  return (
    <div className="form-card">
      <h1 className="heading-large form-card-heading-central">
        Account not yet approved
      </h1>
      <p className="form-card-paragraph text-center">
        Thank you for signing up as a provider with GotTheTest.{" "}
      </p>
      <p className="form-card-paragraph text-center">
        Our team is currently reviewing your medical details. You will be
        notified via email when you can start using your account.
      </p>
      <Button
        variant="secondary"
        onClick={() => auth.signOut()}
        formCard={true}
      >
        Log out
      </Button>
    </div>
  )
}
