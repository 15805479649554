import React from "react"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"

import { FormConfirmation, FormLegend, Button } from "../../shared"
import { SEOTitleTemplate } from "../../../constants"
import { Step1 } from "./Step1"

const defaultState = {
  step: 1,
  data: {
    testId: "",
    result: {
      reading: {
        type: "",
        data: "",
      },
      cliaLabName: "",
      cliaLabNumber: "",
      medicalDirector: "",
      npi: "",
    },
  },
}

export const PCRTest = () => {
  const { addToast } = useToasts()
  const [testData, setTestData] = React.useState(defaultState)

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "PCR Test Result" })} />
      <div className="form-card-large">
        {testData?.step === 1 ? (
          <Step1 addToast={addToast} setTestData={setTestData} />
        ) : testData?.step === 2 ? (
          <FormConfirmation
            title="Test result saved"
            footer={
              <>
                <Button onClick={() => setTestData(defaultState)}>
                  Record another result
                </Button>
                <Button variant="secondary" to="/">
                  Back to dashboard
                </Button>
              </>
            }
          >
            <FormLegend>
              The patient has been notified via email that their result is now
              available
            </FormLegend>
          </FormConfirmation>
        ) : null}
      </div>
    </>
  )
}
