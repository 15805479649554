import React from "react"
import PropTypes from "prop-types"

export const ButtonLink = ({ children, href, onClick, ...props }) => {
  if (onClick) {
    return (
      <button onClick={onClick} className="button-link" {...props}>
        {children}
      </button>
    )
  } else if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="button-link"
        {...props}
      >
        {children}
      </a>
    )
  } else {
    return null
  }
}

ButtonLink.defaultProps = {
  onClick: null,
  href: "",
}

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
}
