import React from "react"
import axios from "axios"

import { Button, FormLegend, FormFooter } from "../../../shared"
import { handleAPIError } from "../../../../utilities"
import { useSelfTest } from ".."
import { ProgressCircle } from "./ProgressCircle"

export const Step5 = () => {
  const { testData, setTestData, addToast } = useSelfTest()
  const [loading, setLoading] = React.useState(false)
  const [alertSent, setAlertSent] = React.useState(false)
  const [timer, setTimer] = React.useState({
    start: new Date(testData.data.startedTime),
    time: 0,
    timeSeconds: "00",
    timeMinutes: "00",
  })

  const logResultReadTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: {
            resultsReadTime: new Date().toISOString(),
          },
          _expand: ["patient"],
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        data: { ...updatedTest },
      }))
      // setLoading not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "save result read time"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }

  React.useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      setTimer((timer) => ({
        ...timer,
        time: Date.now() - timer.start,
        timeSeconds: ("0" + (Math.floor(timer.time / 1000) % 60)).slice(-2),
        timeMinutes: ("0" + (Math.floor(timer.time / 60000) % 60)).slice(-2),
      }))
    }, 100)

    return () => {
      clearInterval(interval)
      setTimer({
        start: new Date(testData.data.startedTime),
        time: 0,
        timeSeconds: "00",
        timeMinutes: "00",
      })
    }
  }, [testData.data.startedTime])

  React.useEffect(() => {
    if (
      testData?.data?.manufacturer === "Orasure Inteliswab" &&
      timer.timeMinutes === "30" &&
      !alertSent
    ) {
      alert(`${testData?.data?.patient?.fullName}'s InteliSwab result is ready`)
      setAlertSent(true)
    }
    return () => {
      setAlertSent(false)
    }
  }, [timer.timeMinutes]) // eslint-disable-line

  return (
    <>
      <FormLegend>
        {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
          <p>Please wait 30 minutes for the InteliSwab test result</p>
        ) : (
          <p>
            Please wait for the test result according to the length of time
            recommended by the test manufacturer
          </p>
        )}
      </FormLegend>
      <FormLegend>
        <p>Patient: {testData?.data?.patient?.fullName}</p>
      </FormLegend>
      <div className="timer">
        <div className="timer-single">
          <span className="timer-single-background" />
          <ProgressCircle
            radius={100}
            stroke={4}
            progress={parseInt(timer.timeMinutes) / 60}
          />
          <div className="timer-single-metric">
            <span className="timer-single-metric-unit">
              {timer.timeMinutes}
            </span>
            <span className="timer-single-metric-name">mins</span>
          </div>
        </div>
        <div className="timer-single">
          <span className="timer-single-background" />
          <ProgressCircle
            radius={100}
            stroke={4}
            progress={parseInt(timer.timeSeconds) / 60}
          />
          <div className="timer-single-metric">
            <span className="timer-single-metric-unit">
              {timer.timeSeconds}
            </span>
            <span className="timer-single-metric-name">secs</span>
          </div>
        </div>
      </div>
      <FormFooter>
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logResultReadTime()}
        >
          Log result read time
        </Button>
      </FormFooter>
    </>
  )
}
