import React from "react"
import ImageUploading from "react-images-uploading"
import PropTypes from "prop-types"

import { Button } from "../.."

export const ImageUploader = ({
  photos,
  updatePhotos,
  illustration,
  variant,
}) => {
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    updatePhotos(imageList)
  }
  return (
    <div className="image-uploader-position">
      <ImageUploading
        value={photos}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="data_url"
        acceptType={["jpg", "png"]}
        maxFileSize={5242880} // 5MB
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className={`image-uploader image-uploader-${variant}`}>
            {photos.length === 0 && (
              <section
                className="image-uploader-button"
                type="button"
                style={isDragging ? { color: "red" } : null}
                onClick={onImageUpload}
                {...dragProps}
              >
                <div className="image-uploader-illustration">
                  {illustration}
                </div>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => onImageUpload}
                >
                  <span className="image-uploader-button-text">
                    <span className="image-uploader-desktop">
                      Click to upload photo
                    </span>
                    <span className="image-uploader-mobile">
                      Tap to take a photo
                    </span>
                  </span>
                </Button>
                <span className="image-uploader-button-helper-text">
                  (Maximum file size: 5mb)
                </span>
              </section>
            )}
            {imageList.map((image, index) => (
              <div key={index} className="image-uploader-photo">
                <img src={image.data_url} alt="" width="112" />
                <button
                  className="button button-secondary image-uploader-photo-replace-button"
                  onClick={() => onImageUpdate(index)}
                  type="button"
                >
                  Replace
                </button>
                <button
                  className="button button-secondary image-uploader-photo-remove-button"
                  onClick={() => onImageRemove(index)}
                  type="button"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  )
}

ImageUploader.propTypes = {
  illustration: PropTypes.node.isRequired,
  photos: PropTypes.array.isRequired,
  updatePhotos: PropTypes.func.isRequired,
  variant: PropTypes.string,
}
