import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { InputSelect, Input } from ".."

export const InputSelectOther = ({ name, label, options, ...props }) => {
  const [field, meta, helpers] = useField(name)
  const [otherShown, setOtherShown] = React.useState(false)

  React.useEffect(() => {
    if (field.value === "Other") {
      setOtherShown(true)
      helpers.setValue("")

      if (meta.error) {
        helpers.setError("")
      }
    } else if (options.some((option) => field.value === option.value)) {
      setOtherShown(false)
    }
  }, [options, meta.error, helpers, field.value])
  return (
    <>
      <InputSelect
        name={name}
        label={label}
        options={[...options, { value: "Other", label: "Other" }]}
        {...props}
      />
      {otherShown ? <Input name={name} label={`'Other' ${label}`} /> : <div />}
    </>
  )
}

InputSelectOther.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
