import React from "react"
import { Link } from "@reach/router"
import PropTypes from "prop-types"

export const ButtonLinkWithIcon = ({ to, icon, iconPosition, children }) => (
  <Link
    className={`button-link-with-icon button-link-with-icon-${iconPosition}`}
    to={to}
  >
    <span className={`icon-${icon} button-link-with-icon-icon`}></span>
    <span className="button-link-with-icon-text">{children}</span>
  </Link>
)

ButtonLinkWithIcon.defaultProps = {
  icon: "arrow-left",
  iconPosition: "left",
}

ButtonLinkWithIcon.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(["arrow-left"]),
  children: PropTypes.node.isRequired,
  iconPosition: PropTypes.oneOf(["left", "right"]),
}
