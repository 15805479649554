import React from "react"
import { Link } from "@reach/router"

import { useAuth } from "../../hooks"
import { Breadcrumbs } from "./Breadcrumbs"

import { ReactComponent as GTTLogoDark } from "../../assets/GotTheTest-logo-dark.svg"

export const Layout = ({ children }) => {
  const auth = useAuth()

  return (
    <>
      <div className="nav">
        <Link className="nav-logo" to="/">
          <GTTLogoDark />
        </Link>
        <Breadcrumbs />
        <div>
          <button className="nav-log-out-button" onClick={() => auth.signOut()}>
            Log out
          </button>
        </div>
      </div>
      <main className="layout">{children}</main>
    </>
  )
}
