import React from "react"
import firebase from "firebase/app"
import "firebase/auth"
import axios from "axios"
import { useToasts } from "react-toast-notifications"

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOIMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

const AuthContext = React.createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}

function useProvideAuth() {
  const { addToast } = useToasts()
  const [user, setUser] = React.useState(null)
  const [signedUp, setSignedUp] = React.useState(false)
  const [creatingAccount, setCreatingAccount] = React.useState(false)
  const [accountApproved, setAccountApproved] = React.useState(false)
  const [providerData, setProviderData] = React.useState({})

  const signUp = async (email, password) => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password)
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          addToast(
            "Sorry, this email is already in use by another account, please use a different email address",
            {
              appearance: "error",
            }
          )
          break
        default:
          addToast(`Could not create account, ${error.message}`, {
            appearance: "error",
          })
      }
    }
  }

  const signIn = async (email, password) => {
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)

      await setBearerToken(response.user, setUser)
      //setSignedUp(true)
    } catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          addToast(
            "Sorry, we can't find an account with those details, please try again",
            {
              appearance: "error",
            }
          )
          break
        case "auth/wrong-password":
          addToast(
            "Sorry, we can't find an account with those details, please try again",
            {
              appearance: "error",
            }
          )
          break
        case "auth/too-many-requests":
          addToast(
            "Too many login attempts, your account has been temporarily disabled. Please try again in 5 minutes",
            {
              appearance: "error",
            }
          )
          break
        default:
          addToast("Something went wrong, please try again", {
            appearance: "error",
          })
      }
    }
  }

  const signOut = async () => {
    try {
      setUser(false)
      setSignedUp(false)
      await firebase.auth().signOut()
    } catch (error) {
      addToast("Could not sign out, please try again", {
        appearance: "error",
      })
    }
  }

  const changeEmail = async (newEmail) => {
    try {
      await firebase.auth().currentUser.updateEmail(newEmail)
    } catch (error) {
      console.log(error)
      addToast("Could not update email address, " + error.message, {
        appearance: "error",
      })
    }
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setBearerToken(user, setUser)
        //setSignedUp(true)
      } else {
        setUser(false)
        //setSignedUp(false)
      }
    })
    return () => unsubscribe()
  }, [])

  const updateProfile = async (updates) => {
    try {
      await firebase.auth().currentUser.updateProfile(updates)
    } catch (error) {
      console.log(error)
      addToast("Could update profile, " + error.message, {
        appearance: "error",
      })
    }
  }

  return {
    user,
    signedUp,
    creatingAccount,
    providerData,
    accountApproved,
    setCreatingAccount,
    setSignedUp,
    setProviderData,
    setAccountApproved,
    signUp,
    signIn,
    signOut,
    changeEmail,
    updateProfile,
  }
}

const setBearerToken = async (firebaseUser, setAppUser) => {
  const token = await firebaseUser.getIdToken()
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
  // Must only set user and active app after Bearer token is set
  setAppUser(firebaseUser)
}
