import React from "react"
import PropTypes from "prop-types"

export const FormFooter = ({ children }) => (
  <footer className="form-footer">{children}</footer>
)

FormFooter.propTypes = {
  children: PropTypes.node.isRequired,
}
