import React from "react"
import PropTypes from "prop-types"

export const ModalImageContainer = ({ images, svg }) => (
  <div
    className={`modal-image-container ${
      svg ? "modal-image-container-wide" : ""
    }`}
  >
    {images &&
      images.map((image, index) => (
        <div className="modal-image-container-image" key={index}>
          {svg ? image.src : <img src={image.src} alt={image.alt} />}
        </div>
      ))}
  </div>
)

ModalImageContainer.defaultProps = {
  svg: false,
}

ModalImageContainer.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.oneOf([PropTypes.string, PropTypes.object]).isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  svg: PropTypes.bool,
}
