import React from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"

export const Button = ({
  children,
  formCard,
  loading,
  onClick,
  to,
  variant,
  ...props
}) => {
  if (to) {
    return (
      <Link
        to={to}
        className={`button button-${variant}${
          formCard ? " button-form-card" : ""
        }`}
      >
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button
        {...props}
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}`}
        onClick={onClick}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  } else {
    return (
      <button
        {...props}
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}`}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  }
}

Button.defaultProps = {
  variant: "primary",
  loading: false,
  to: "",
  onClick: null,
  formCard: false,
}

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "delete"])
    .isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  formCard: PropTypes.bool,
}
