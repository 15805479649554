import React from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"

export const Breadcrumb = ({ name, link, chevron }) => (
  <li className="breadcrumb">
    {chevron && <span className="icon-chevron-right breadcrumb-icon" />}
    <Link className="breadcrumb-link" to={link}>
      {name}
    </Link>
  </li>
)

Breadcrumb.defaultProps = {
  chevron: true,
}

Breadcrumb.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  chevron: PropTypes.bool,
}
