import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"

import {
  Button,
  Form,
  FormLegend,
  FormSection,
  Input,
  InputSelect,
  FormFooter,
} from "../../../shared"
import { handleAPIError } from "../../../../utilities"
import { PCRResultOptions } from "../../../../constants"
import { useAuth } from "../../../../hooks"

export const Step1 = ({ addToast, setTestData }) => {
  const auth = useAuth()

  const isMedicalDirector =
    auth?.providerData?.jobTitle === "Site Medical Director"

  const onSubmit = async (values, { setSubmitting }) => {
    if (!isMedicalDirector) {
      values.observingAssistantName = auth.user.displayName
    } else {
      values.orderingPhysician = `${auth?.providerData?.firstName} ${auth?.providerData?.lastName}`
      values.npi = auth?.providerData?.npi
    }

    try {
      const response = await axios({
        method: "post",
        url: "/tests/results/update-pending-result",
        data: values,
      })
      const newTest = response.data
      setTestData(() => ({
        step: 2,
        data: { ...newTest },
      }))
      // setSubmitting not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "save PCR result"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{
        testId: "",
        result: {
          reading: {
            type: "PCR",
            data: "",
          },
        },
        cliaLabName: "",
        cliaLabNumber: "",
        orderingPhysician: "Sabine Barlatt",
        npi: "1760730091",
      }}
      validationSchema={Yup.object().shape({
        testId: Yup.string().required(
          "Please enter the Test ID for the test you're recording a result for"
        ),
        result: Yup.object().shape({
          reading: Yup.object().shape({
            data: Yup.string().required("Please choose a result reading"),
          }),
        }),
        cliaLabName: Yup.string().required(
          "Please enter the name for the CLIA Lab that processed the result"
        ),
        cliaLabNumber: Yup.string().required(
          "Please enter the number for the CLIA Lab that processed the result"
        ),
        orderingPhysician: isMedicalDirector
          ? Yup.string()
          : Yup.string().required(
              "Please enter the name of the Site Medical Director you're acting under"
            ),
        npi: isMedicalDirector
          ? Yup.string()
          : Yup.string().required(
              "Please enter the NPI of the Site Medical Director you're acting under"
            ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormLegend>
            <p>The Test ID sticker can be found on the PCR Test packaging</p>
          </FormLegend>
          <FormSection>
            <Input label="Test ID" name="testId" />
            <InputSelect
              label="Result reading"
              name="result.reading.data"
              options={PCRResultOptions}
            />
          </FormSection>
          <FormSection>
            <Input label="CLIA Lab Name" name="cliaLabName" />
            <Input label="CLIA Lab Number" name="cliaLabNumber" />
          </FormSection>
          {!isMedicalDirector && (
            <FormSection>
              <Input
                label="Site Medical Director Name"
                name="orderingPhysician"
              />
              <Input label="Site Medical Director NPI" name="npi" />
            </FormSection>
          )}
          <FormFooter>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Save result
            </Button>
          </FormFooter>
        </Form>
      )}
    </Formik>
  )
}
