import React from "react"
import PropTypes from "prop-types"

export const FormLegend = ({ children, hasFormElements, button }) => (
  <div
    className={`form-legend${
      hasFormElements ? " form-legend-has-elements" : ""
    }${button ? " form-legend-button" : ""}`}
  >
    {children}
  </div>
)

FormLegend.defaultProps = {
  hasFormElements: false,
  button: false,
}

FormLegend.propTypes = {
  children: PropTypes.node.isRequired,
  hasFormElements: PropTypes.bool,
  button: PropTypes.bool,
}
