import React from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"

export const ResultFormButton = ({ name, link }) => (
  <Link className="input-result-form" to={link}>
    <span className="input-result-form-name">{name}</span>
  </Link>
)

ResultFormButton.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}
