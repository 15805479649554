import React from "react"
import { Helmet } from "react-helmet"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import { navigate } from "@reach/router"
import firebase from "firebase/app"
import "firebase/auth"

import { SEOTitleTemplate } from "../../../constants"
import { Form, Input, Button } from "../../shared"
import { handleAPIError } from "../../../utilities"
import { useAuth } from "../../../hooks"
import { InputSelectJobTitle } from "./InputSelectJobTitle"

export const EnterMedicalDetails = () => {
  const [isSiteDirector, setIsSiteDirector] = React.useState(false)
  const { addToast } = useToasts()
  const auth = useAuth()

  const onSubmit = async (values, { setSubmitting }) => {
    if (!isSiteDirector) {
      values.npi = "No NPI"
    }
    try {
      await axios({
        method: "post",
        url: "/provider/create",
        data: values,
      })
      await updateDisplayName(values.firstName + " " + values.lastName)
      auth.setSignedUp(true)
      navigate("/")
    } catch (error) {
      addToast(handleAPIError(error, "create provider"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  const updateDisplayName = async (newName) => {
    if (auth.user) {
      try {
        await firebase.auth().currentUser.updateProfile({
          displayName: newName,
        })
      } catch (error) {
        addToast(handleAPIError(error, "update provider profile"), {
          appearance: "error",
        })
      }
    } else {
      addToast("Could not update provider profile, you are not signed in", {
        appearance: "error",
      })
    }
  }

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your Medical Details" })} />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          npi: "",
          jobTitle: "",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required("Please your first name"),
          lastName: Yup.string().required("Please your last name"),
          npi: isSiteDirector
            ? Yup.string().required(
                "Please enter your National Provider Identifier"
              )
            : Yup.string(),
          jobTitle: Yup.string().required("Please choose a job title"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form variant="card">
            <h1 className="heading-large form-card-heading-central">
              Your medical details
            </h1>
            <p className="form-card-paragraph text-center">
              Almost done! We just need to your medical details to verify
              patient test results
            </p>
            <Input name="firstName" label="First name" />
            <Input name="lastName" label="Last name" />
            <InputSelectJobTitle
              name="jobTitle"
              label="Your job title"
              options={[
                {
                  label: "Site Medical Director",
                  value: "Site Medical Director",
                },
                {
                  label: "Observing Assistant",
                  value: "Observing Assistant",
                },
              ]}
              setIsSiteDirector={setIsSiteDirector}
            />
            {values.jobTitle === "Site Medical Director" && (
              <Input name="npi" label="Your NPI" />
            )}
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              formCard={true}
            >
              Save medical details
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
