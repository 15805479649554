import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"
import firebase from "firebase/app"
import "firebase/storage"

import {
  Button,
  Form,
  FormLegend,
  FormSection,
  InputSelect,
  ImageUploader,
  FormFooter,
} from "../../../shared"
import { useSelfTest } from ".."
import { handleAPIError } from "../../../../utilities"
import { useAuth } from "../../../../hooks"
import { antigenResultOptions } from "../../../../constants"

import { ReactComponent as AntigenTest } from "../../../../assets/rapid-antigen-test.svg"

export const Step6 = ({ image, inteliSwabImage }) => {
  const auth = useAuth()
  const { testData, setTestData, addToast } = useSelfTest()
  const [photos, setPhotos] = React.useState([])

  const onSubmit = async (values, { setSubmitting }) => {
    let requestResult = {
      reading: {
        type: "neg-pos",
        data: values.reading,
      },
    }
    try {
      if (photos.length > 0) {
        const date = new Date().toISOString()
        const filePath = `users/${auth.user.uid}/images/${date}-${photos[0].file.name}`
        await firebase.storage().ref(filePath).put(photos[0].file)
        requestResult.images = [filePath]
      }

      const response = await axios({
        method: "post",
        url: "/tests/record-result",
        data: {
          testId: testData.data.id,
          result: requestResult,
        },
      })
      const testWithTestResult = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        data: { ...testWithTestResult },
      }))
    } catch (error) {
      addToast(handleAPIError(error, "save result"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{
        reading: "",
      }}
      validationSchema={Yup.object().shape({
        reading: Yup.string().required(
          "Please enter the result reading of your test"
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormLegend>
            <p>
              Please ask your patient to hold up their test result so you can
              take a screenshot (Optional)
            </p>
            <p>
              Please also enter the result reading into the Mend appointment
              notes
            </p>
          </FormLegend>
          <FormSection>
            <div>
              <ImageUploader
                photos={photos}
                updatePhotos={setPhotos}
                illustration={<AntigenTest />}
                variant="self-test"
              />
              <span className="input-error-text image-uploader-error" />
              <InputSelect
                label="Reading shown"
                name="reading"
                options={antigenResultOptions}
              />
            </div>
            {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
              inteliSwabImage
            ) : (
              <img
                className="image-uploader-example"
                src={image}
                alt="Antigen Test Results"
              />
            )}
          </FormSection>
          <FormFooter>
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Save result
            </Button>
          </FormFooter>
        </Form>
      )}
    </Formik>
  )
}

Step6.propTypes = {
  image: PropTypes.string.isRequired,
  inteliSwabImage: PropTypes.object.isRequired,
}
