import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"

import { Button, Form, Input } from "../../shared"
import { handleAPIError } from "../../../utilities"
import { SEOTitleTemplate } from "../../../constants"

export const SendPasswordReset = () => {
  const { addToast } = useToasts()
  const [submitted, setSubmitted] = React.useState(false)

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await axios({
        method: "post",
        url: "/user/forgot-password",
        data: values,
      })
      setSubmitted(true)
    } catch (error) {
      addToast(handleAPIError(error, "send password reset"), {
        appearance: "error",
      })
    }
    setSubmitting(false)
  }
  return submitted ? (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Password reset sent" })} />
      <div className="form-card">
        <span className="icon-tick-circle-fill form-card-confirmation" />
        <h1 className="heading-large form-card-heading-central">
          Check your email
        </h1>
        <p className="form-card-paragraph form-card-paragraph-extra-margin-bottom text-center">
          If you have an account, we've sent an email with a link to reset your
          password.
        </p>
        <Button variant="secondary" to="/" formCard={true}>
          Back to login
        </Button>
      </div>
    </>
  ) : (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Reset your password" })} />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter your email"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            <h1 className="heading-large form-card-heading-left">
              Forgotten your password?
            </h1>
            <p className="form-card-paragraph">
              We’ll send a password reset link to your email so you can log back
              in.
            </p>
            <Input label="Your email" name="email" />
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              formCard={true}
            >
              Reset password
            </Button>
            <Button variant="tertiary" to="/" formCard={true}>
              Back to login
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
