import React from "react"
import ReactDOM from "react-dom"
import { ToastProvider } from "react-toast-notifications"
import axios from "axios"
import { LocationProvider, globalHistory } from "@reach/router"
import { QueryParamProvider } from "use-query-params"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ProvideAuth } from "./hooks"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE

ReactDOM.render(
  <LocationProvider>
    <QueryParamProvider {...{ path: "/" }} reachHistory={globalHistory}>
      <ToastProvider
        placement="bottom-center"
        autoDismiss
        autoDismissTimeout={5000}
      >
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </ToastProvider>
    </QueryParamProvider>
  </LocationProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
