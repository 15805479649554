import React from "react"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"

import { Step1 } from "./Step1"
import { Step3 } from "./Step3"
import { Step4 } from "./Step4"
import { Step5 } from "./Step5"
import { Step6 } from "./Step6"
import { SEOTitleTemplate } from "../../../constants"
import { Button, FormConfirmation, FormLegend } from "../../shared"

// importing images in parent component to give time for them to load ahead of future steps
import NasalSample from "../../../assets/self-test/antigen/step-2-1.jpg"
import InsertSample from "../../../assets/self-test/antigen/step-2-2.jpg"
import RemoveSample from "../../../assets/self-test/antigen/step-3-1.jpg"
import DropLiquidOnTest from "../../../assets/self-test/antigen/step-3-2.jpg"
import ExampleTestResults from "../../../assets/self-test/antigen/test-results.jpg"
// InteliSwab Images
import { ReactComponent as InteliSwabLeft } from "../../../assets/self-test/antigen/InteliSwab/swab-left.svg"
import { ReactComponent as InteliSwabRight } from "../../../assets/self-test/antigen/InteliSwab/swab-right.svg"
import { ReactComponent as InteliSwirl } from "../../../assets/self-test/antigen/InteliSwab/swirl.svg"
import { ReactComponent as InteliResults } from "../../../assets/self-test/antigen/InteliSwab/results.svg"

const SelfTestContext = React.createContext()

export const useSelfTest = () => {
  return React.useContext(SelfTestContext)
}

const defaultState = {
  step: 1,
  data: {
    batch: "",
    createdTime: "",
    id: "",
    manufacturer: "",
    patient: {
      id: "",
      fullName: "",
    },
    sampleType: "",
    selfTest: true,
    status: "",
    type: "",
    resultsReadTime: "",
    startedTime: "",
    user: "",
  },
}

const useProvideSelfTest = () => {
  const { addToast } = useToasts()
  const [testData, setTestData] = React.useState(defaultState)
  const resetTestData = () => {
    setTestData(defaultState)
  }
  return { testData, setTestData, resetTestData, addToast }
}

export const AntigenTest = () => {
  const testData = useProvideSelfTest()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [testData.testData.step])

  return (
    <SelfTestContext.Provider value={testData}>
      <Helmet title={SEOTitleTemplate({ title: "Antigen Test Result" })} />
      <div className="form-card-large">
        {testData.testData.step === 1 ? (
          <Step1 />
        ) : testData.testData.step === 3 ? (
          <Step3
            images={[
              {
                src: NasalSample,
                alt: "Take a nasal sample",
              },
              {
                src: InsertSample,
                alt: "Insert sample into extraction tube",
              },
            ]}
            inteliSwabImages={[
              {
                src: <InteliSwabLeft />,
                alt: "Insert swab into your left nostril and rotate 15 times",
              },
              {
                src: <InteliSwabRight />,
                alt: "Insert swab into your right nostril and rotate 15 times",
              },
            ]}
          />
        ) : testData.testData.step === 4 ? (
          <Step4
            images={[
              {
                src: RemoveSample,
                alt: "Remove nasal sample from extraction tube",
              },
              {
                src: DropLiquidOnTest,
                alt: "Drop sample liquid onto test strip",
              },
            ]}
            inteliSwabImages={[
              {
                src: <InteliSwirl />,
                alt: "Insert your sample into the InteliSwab extraction tube and swirl 10 times",
              },
            ]}
          />
        ) : testData.testData.step === 5 ? (
          <Step5 />
        ) : testData.testData.step === 6 ? (
          <Step6
            image={ExampleTestResults}
            inteliSwabImage={<InteliResults />}
          />
        ) : testData.testData.step === 7 ? (
          <FormConfirmation
            title="Test result saved"
            footer={
              <>
                <Button onClick={() => testData.setTestData(defaultState)}>
                  Record another result
                </Button>
                <Button variant="secondary" to="/">
                  Back to dashboard
                </Button>
              </>
            }
          >
            <FormLegend>
              You're patient will now be able to see their new test result by
              refreshing their diagnostics screen
            </FormLegend>
          </FormConfirmation>
        ) : null}
      </div>
    </SelfTestContext.Provider>
  )
}
