import React from "react"
import PropTypes from "prop-types"

export const FormConfirmation = ({ title, children, footer }) => (
  <div className="form-confirmation">
    <span className="icon-tick-circle-fill form-confirmation-icon" />
    <h2 className="heading-medium form-confirmation-heading">{title}</h2>
    <div>{children}</div>
    <footer className="heading-confirmation-footer">{footer}</footer>
  </div>
)

FormConfirmation.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
}
