import React from "react"
import PropTypes from "prop-types"

export const IconButton = ({ icon, ...props }) => (
  <button className="icon-button" {...props}>
    <span className={`icon-button-icon icon-${icon}`}></span>
  </button>
)

IconButton.propTypes = {
  icon: PropTypes.oneOf([
    "arrow-left",
    "arrow-right",
    "arrow-top",
    "arrow-bottom",
    "eye",
    "eye-off",
    "cross",
    "more",
  ]).isRequired,
}
