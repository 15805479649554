import React from "react"
import { Router } from "@reach/router"

import { SendPasswordReset } from "./SendPasswordReset"
import { ScrollToTop } from ".."

export const ForgotPassword = () => (
  <Router>
    <ScrollToTop path="/">
      <SendPasswordReset path="/" />
    </ScrollToTop>
  </Router>
)
