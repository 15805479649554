import React from "react"
import ReactSelect from "react-select"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from "../../../shared"

export const InputSelectJobTitle = ({
  label,
  name,
  options,
  placeholder,
  setIsSiteDirector,
}) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = (opt) => {
    if (opt.value === "Site Medical Director") {
      setIsSiteDirector(true)
    } else {
      setIsSiteDirector(false)
    }
    helpers.setValue(opt.value)
  }

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      <Label field={name}>{label}</Label>
      <ReactSelect
        placeholder={placeholder ? placeholder : "Please select..."}
        styles={{
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "#ffae33 !important"
              : state.isFocused
              ? "#f2f2f5"
              : "white",
          }),
        }}
        {...field}
        type="select"
        className={`input-select${
          meta.touched && meta.error ? " input-select-error" : ""
        }`}
        classNamePrefix="react-select"
        options={options}
        onChange={(opt) => handleChange(opt)}
        value={options.find((opt) => opt.value === field.value)}
      />
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

InputSelectJobTitle.defaultProps = {
  placeholder: "",
}

InputSelectJobTitle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
}
