import React from "react"
import { Helmet } from "react-helmet"

import { ResultFormButton } from "./ResultFormButton"
import { SEOTitleTemplate } from "../../../constants"

export const Dashboard = () => (
  <div className="result-forms">
    <Helmet
      title={SEOTitleTemplate({ title: "Choose a Test Result Type to Input" })}
    />
    <section className="result-form-group">
      <h1 className="heading-large result-form-group-heading">COVID Tests</h1>
      <div className="result-form-group-list">
        <ResultFormButton name="Antigen Test Result" link="/antigen-test" />
        <ResultFormButton name="PCR Test Result" link="/pcr-test" />
      </div>
    </section>
  </div>
)
