import React from "react"
import { Router } from "@reach/router"

import { useAuth } from "../../hooks"
import { VerifyEmail } from "./VerifyEmail"
import { EnterMedicalDetails } from "./EnterMedicalDetails"
import { Login, ScrollToTop } from ".."

export const CreateAccount = () => {
  const auth = useAuth()

  return auth.user && auth.creatingAccount ? (
    <Router>
      <ScrollToTop path="/">
        <VerifyEmail path="/confirm-email" />
        <EnterMedicalDetails path="/enter-medical-details" />
      </ScrollToTop>
    </Router>
  ) : (
    <Login />
  )
}
