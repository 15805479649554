import React from "react"
import { Router } from "@reach/router"

import {
  InputResults,
  Layout,
  AccountNotApproved,
  ScrollToTop,
} from "./components"
import { useAuth } from "./hooks"

export const ProtectedApp = () => {
  const auth = useAuth()

  return auth.accountApproved ? (
    <Layout>
      <Router>
        <ScrollToTop path="/">
          <InputResults path="/*" />
        </ScrollToTop>
      </Router>
    </Layout>
  ) : (
    <AccountNotApproved />
  )
}
